.formLable {
    font-family: DM Sans;
    font-size: 13px;
}

.formSelect {
    height: 50%;
    border-radius: 0px;
    font-family: DM Sans;
    font-size: 13px;
}

.formLooksGood {
    font-family: DM Sans;
    font-size: 13px;
}

.formInvalid {
    font-family: DM Sans;
    font-size: 13px;
}

.picUploadBtn {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #3498db;
}

.picUploadBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #34495e;
}

.agreeToTerms {
    font-size: 13px;
    font-family: DM Sans;
}

.uploadBtn {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #8D448B;
}

.uploadBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #3498db;
}

.modalCancelBtn {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #e74c3c;
}
.modalCancelBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #34495e;
}

.planStandardCard {
    border: 2px solid #e74c3c;
    height: 100%;
}

.planPremiumCard{
    border: 2px solid #f1c40f;
}

.standardBtn {
    border: 0px;
    border-radius: 0px;
    height: 40px;
    width: 130px;
    font-family: DM Sans;
    font-size: 15px;
    background-color: #e74c3c;
}
.standardBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 40px;
    width: 130px;
    font-family: DM Sans;
    font-size: 15px;
    background-color: #34495e;
}
.premiumBtn {
    border: 0px;
    border-radius: 0px;
    height: 40px;
    width: 130px;
    font-family: DM Sans;
    font-size: 15px;
    background-color: #f1c40f;
}
.premiumBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 40px;
    width: 130px;
    font-family: DM Sans;
    font-size: 15px;
    background-color: #34495e;
}
.btn-close {
    background-color: #34495e;
}


.upgradePlanBtn {
    border: 0px;
    border-radius: 0px;
    height: 40px;
    width: 130px;
    font-family: DM Sans;
    font-size: 15px;
    background-color: #2ecc71;
}
.upgradePlanBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 40px;
    width: 130px;
    font-family: DM Sans;
    font-size: 15px;
    background-color: #f1c40f;
}
