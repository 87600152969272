#search-input {
    border: none;
    border-radius: 30px;
    outline: none;
    box-shadow: none;
    color: rgba(52, 61, 72, 0.8);
    height: 50px;
    width: 300px;
    background: url("../searchRoom.png") no-repeat;
    background-size: 30px 30px;
    background-position: 10%;
    box-sizing: border-box;
    text-indent:80px;
    box-shadow:0 0 5px 5px rgb(235, 229, 229);
}

#search-input[type=search] {
     color: rgba(52, 61, 72, 0.8);
}

#search-input::placeholder { 
    color: rgba(52, 61, 72, 0.8);
    opacity: 1; 
    text-align: center;
    font-weight: 500;
    font-family: DM Sans;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 2em;
    width: 2em;
    border-radius: 50em;
    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }
  
  input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: .3;
    pointer-events: all;
  }
  
  input[type="search"].dark::-webkit-search-cancel-button {
    filter: invert(1);
  }

  .find-room-btn {
      background-color: transparent;
      transition: 0.8s;
      color: #020718;
      border-color: #8D448B;
      border-radius: 10px;
      font-family: DM Sans;
      width: 90%;
  }
  .find-room-btn:hover {
      background-color: #2c3e50;
      color: #fff;
      transition: 0.8s;
      border-color: #8D448B;
      border-radius: 10px;
  }

  .find-room-btn:focus {
    background-color: #2c3e50;
    color: #fff;
    transition: 0.8s;
    outline: none !important;
    box-shadow: none !important;
  }

  .find-room-book-btn {
    background-color: transparent;
    transition: 0.8s;
    color: #020718;
    border-color: #8D448B;
    border-radius: 10px;
    font-family: DM Sans;
    width: 100%;
    height: 50%px;
  }

  .find-room-book-btn:hover {
    background-color: #2c3e50;
    color: #fff;
    transition: 0.8s;
    border-color: #8D448B;
    border-radius: 10px;
}

.find-room-book-btn:focus {
  background-color: #2c3e50;
  color: #fff;
  transition: 0.8s;
  outline: none !important;
  box-shadow: none !important;
}

.find-room-card:hover {
    box-shadow:0 0 5px 5px rgb(202, 193, 193);
    transition: 0.8s;
}
.find-room-card {
    transition: 0.8s;
    margin-top: 5px;
}

.carousel-control-next-icon {
  background-color: #7c7f83;
  border-radius: 50px;
  /*background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;*/
}

.carousel-control-prev-icon {
  background-color: #7c7f83;
  border-radius: 50px;
}

.searchBox {
  padding: 10px;
  border-radius: 50px;
}

.inputBox {
  border-radius: 50px;
  font-family: DM Sans;
  text-align: center;
  font-weight: bold;
}

.searchBtn {
  margin-top: 8px;
  border-radius: 50px;
}
