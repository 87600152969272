.sidebarContainer {
    margin-top: 110px;
    background-color: #eee;
}

.leftCard{
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 5px 5px 5px rgb(196, 193, 193);
}

.rightCard{
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 5px 5px 5px rgb(196, 193, 193);
}