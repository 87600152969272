.OwnerContact-btn {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
    color: white;
    background-color: #d35400;
    border: 0;
    border-radius: 4px;
    font-family: DM Sans;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.OwnerContact-btn:hover {
  background-color: #020718;
}
.OwnerContact-btn:active {
  background-color: #020718;
}
.OwnerContact-btn:focus {
  background-color: #020718;
  outline: none !important;
  box-shadow: none !important;
}


.modalPayBtn {
  border: 0px;
  border-radius: 0px;
  height: 30px;
  width: 100px;
  font-family: DM Sans;
  font-size: 12px;
  background-color: #2ecc71;
}

.modalPayBtn:hover {
  border: 0px;
  border-radius: 0px;
  height: 30px;
  width: 100px;
  font-family: DM Sans;
  font-size: 12px;
  background-color: #34495e;
}