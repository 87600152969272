.tenantDetailsContainer {
    margin-top: 110px;
    background-color: #eee;
}

.tenantDetailsCard{
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 5px 5px 5px rgb(196, 193, 193);
}

.tenantDetailsLabel {
    font-family: DM Sans;
    font-size: 15px;
}

.tenantDetailsInput {
    height: 50%;
    border-radius: 0px;
    font-family: DM Sans;
    font-size: 13px;
}

.tenantDetailsLookGood {
    font-family: DM Sans;
    font-size: 13px;
}

.tenantDetailsInvalid {
    font-family: DM Sans;
    font-size: 13px;
}

.tenantDetailsBtn {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #8D448B;
    margin-top: 40px;
}

.tenantDetailsBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #3498db;
    margin-top: 40px;
}