@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
#features_card {
    cursor: pointer;
    width: auto;
    height: auto;
    background-color: white;
    border-radius: 10px;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    transition: transform 0.5s ease 0s, opacity 300ms ease 0s;
    opacity: 1;
}

#features_card:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

#ll {
    font-size: 20px;
    line-height: 24px;
    color: #271bc1;
    font-weight: 500;
    opacity: 1;
    text-align: left;
    margin-left: 10px;

}

#imgicon {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-right:auto;
}
#imgicon2 {
    width: 50px;
    height: 50px;
    position: relative;
    top: 15px;
    left:20px;
}


#features {
    background: #f6f6f6;
}

.features-container {
    background: #f6f6f6;
    padding-bottom: 20px;
    margin-top: 30px;
}

.card-container {
    margin-top: 20px;
}
