@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=Roboto:wght@300&display=swap'); 
.intro {
    display: table;
    width: 100%;
    padding: 0;
    background: url('../background.jpg') center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  
  .kk{
    box-sizing:border-box;
      color: #333;
      font-family: DM Sans,sans-serif;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: -0.5px;
      line-height: 1.4;
  }
  #jk{
    box-sizing: border-box;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.53;
    font-weight: 750 ;
    max-width: 440px;
    margin-top: 15px;
    color: black;
  
  }
  
  .intro h1 {
    font-family: "DM Sans";
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  .intro p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  header .intro-text {
    padding-top: 180px;
    padding-bottom: 100px;
    text-align: center;
  }

  .form-div {
    background: url('../location.png') no-repeat;
    background-size: 40px 40px;
    background-position: 1%;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    height: 60px;
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid var(--theme-ui-colors-primary,#8D448B);
    padding: 4px 25px 0px 50px;
    position: relative;
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 2.5;
    font-family: DM Sans;
    color: #0F2137;
  }

  #button{
      box-sizing: border-box;
      margin: 0;
      min-width: 0;
      text-align: center;
      line-height: inherit;
      text-decoration: none;
      border: 0;
      background-color: var(--theme-ui-colors-primary,#8D448B);
      font-family: DM Sans;
      border-radius: 5px;
      cursor: pointer;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      padding: 0 30px;
      transition: 0.3s ease-in-out 0s;
      white-space: nowrap;
      color: white;
      font-size: 16px;
      font-weight: 700;
      margin-top: 10px;
      min-height: 50px;
      width: 90%;
      margin-bottom: 20px;
  }
  #button:hover{
    background-color:rgb(59,59,59);
  }

