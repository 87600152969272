@keyframes ldio-ae39cstvvm4 {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-ae39cstvvm4 div {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 10px solid #8d448b;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-ae39cstvvm4 div {
  animation: ldio-ae39cstvvm4 1s linear infinite;
  top: 100px;
  left: 100px
}
.loadingio-spinner-rolling-od70bu4ftmr {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background:none;
}
.ldio-ae39cstvvm4 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ae39cstvvm4 div { box-sizing: content-box; }