.Rebook-btn{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: inline-block;
    text-decoration: none;
    font-size: inherit;
    color: white;
    background-color: #d35400;
    border: 0;
    border-radius: 4px;
    font-family: DM Sans;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.Rebook-btn:hover {
  background-color: #020718;
}
.Rebook-btn:active {
  background-color: #020718;
}
.Rebook-btn:focus {
  background-color: #020718;
  outline: none !important;
  box-shadow: none !important;
}

.confirmModalYesBtn {
  border: 0px;
  border-radius: 0px;
  height: 30px;
  width: 100px;
  font-family: DM Sans;
  font-size: 12px;
  background-color: #2ecc71;
}

.confirmModalYesBtn:hover {
  border: 0px;
  border-radius: 0px;
  height: 30px;
  width: 100px;
  font-family: DM Sans;
  font-size: 12px;
  background-color: #7f8c8d;
}

.confirmModalNoBtn {
  border: 0px;
  border-radius: 0px;
  height: 30px;
  width: 100px;
  font-family: DM Sans;
  font-size: 12px;
  background-color: #e74c3c;
}

.confirmModalNoBtn:hover {
  border: 0px;
  border-radius: 0px;
  height: 30px;
  width: 100px;
  font-family: DM Sans;
  font-size: 12px;
  background-color: #7f8c8d;
}