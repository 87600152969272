 
.modalCloseBtn {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #e74c3c;
}

.modalCloseBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #7f8c8d;
}

.confirmBtn {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #2ecc71;
}

.confirmBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #e67e22;
}
.declineBtn {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #c0392b;
    margin-left: 20px;
}

.declineBtn:hover {
    border: 0px;
    border-radius: 0px;
    height: 30px;
    width: 100px;
    font-family: DM Sans;
    font-size: 12px;
    background-color: #95a5a6;
}