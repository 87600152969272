/* #cities-img:hover {
    transition: all 0.3s;
    transform: scale(1.1);
} */
#cities-img {
    border-radius: 5px;
    box-shadow: 8px 10px 7px #d1d1d1;
    transition: transform 0.5s ease 0s, opacity 300ms ease 0s;
}

#cities-img:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}