html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0; 
    background: transparent; 
}
::-webkit-scrollbar-thumb {
    background: #FF0000;
}
