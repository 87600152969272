.navbar {
    -webkit-box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
    -moz-box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
    box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
  }
  
  .navbar-nav {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .navbar-color {
    background: transparent !important;
    transition: 0.8s;
  }
  .navbar-color2 {
    background: #fff !important;
    transition: 0.8s;
  }

.login-btn {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: inline-block;
    text-decoration: none;
    font-size: inherit;
    color: white;
    background-color: #8D448B;
    border: 0;
    border-radius: 4px;
    font-family: DM Sans;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.login-btn:hover {
  background-color: #020718;
}
.login-btn:active {
  background-color: #020718;
}
.login-btn:focus {
  background-color: #020718;
  outline: none !important;
  box-shadow: none !important;
}

.modal-content {
  border-radius: 10px;
}

/* landlord card */ 

.login-landlord-card-black {
  width: auto;
  height: 100%;
  background-color: #020718 ;
  border: 1px solid rgba(243,244,245,0.15);
  color: #fff;
  border-radius: 10px;
  margin-top: 10px;
}

#login-landlord-card-black-img {
  width: 50px;
  margin-left: 20px;
  margin-top: 20px;
}


.login-landlord-card-input {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  border:1px solid #fff;
  font-family: DM Sans;
}
.login-landlord-card-input::placeholder {
  color: #fff;
  font-family: DM Sans;
  text-align: center;
}

.login-landlord-card-input:focus,.login-landlord-card-input:active {
  outline: none !important;
  box-shadow: none !important;
}

.login-landlord-card-send-otp-btn {
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid #fff;
  margin-top: 5px;
  font-family: DM Sans;
}

.login-landlord-card-send-otp-btn:hover {
  background-color: #353536;
  border-color: #fff;
}
.login-landlord-card-send-otp-btn:active {
  background-color: #020718;
}
.login-landlord-card-send-otp-btn:focus {
  background-color: #020718;
  outline: none !important;
  box-shadow: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/* tenant card */

.login-tenant-card-white {
  width:auto;
  height: 100%;
  background-color: #fff ;
  border: 2px solid #8D448B;
  color: #020718;
  border-radius: 10px;
  margin-top: 10px;
}

#login-tenant-card-white-img {
  width: 50px;
  margin-left: 20px;
  margin-top: 20px;
}

.login-tenant-card-input {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  background-color: transparent;
  color: #020718;
  text-align: center;
  border:1px solid #8D448B;
  font-family: DM Sans;
}
.login-tenant-card-input::placeholder {
  color: #020718;
  font-family: DM Sans;
  text-align: center;
}

.login-tenant-card-input:focus,.login-tenant-card-input:active {
  outline: none !important;
  box-shadow: none !important;
}

.login-tenant-card-send-otp-btn {
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid #353536;
  margin-top: 5px;
  font-family: DM Sans;
  color: #020718;
}

.login-tenant-card-send-otp-btn:hover {
  background-color: #353536;
  border-color: #fff;
}
.login-tenant-card-send-otp-btn:active {
  background-color: #020718;
}
.login-tenant-card-send-otp-btn:focus {
  background-color: #020718;
  outline: none !important;
  box-shadow: none !important;
}

.landlordLoginSpinner {
  margin-top: 10px;
}

.dropdown-toggle {
  width: 40px;
  border-radius: 50%;
  background-color: transparent;
  content: none;
  border: none;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.24);

  margin-right: 10px;
}
.dropdown-toggle::after {
  content: none;
}
.dropdown-toggle:hover {
  width: 40px;
  border-radius: 50%;
  background-color: transparent;
}
.dropdown-toggle:focus {
  width: 40px;
  border-radius: 50%;
  background-color: transparent !important;
  border: none !important;
}
.dropdown-toggle:active {
  width: 40px;
  border-radius: 50%;
  background-color: transparent !important;
  border: none !important;
}
.freeListing{
    background-color: #fff;
    text-transform: capitalize;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    
}
.freeTag{
  display: inline-block;
  margin-left: 6px;
  padding: 0 6px;
  font-size: 10.5px;
  line-height: 18px;
  color: #fff;
  background-color: #f22b68;
}