@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
.pricing-container {
    background-color: #020718;
}

#pricing-plan-text {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    font-family: 'DM Sans';
}
#pricing-plan-text2 {
    font-size: 25px;
    font-weight: 300;
    color: #fff;
    font-family: 'DM Sans';
}

.pricing-btn-container {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(255,255,255,0.2);
    margin: 0 auto 30px;
    max-width: 195px;
    padding: 8px;

}

#pricing-btn-active {
    background-color: #fff;
    color: #343D48;
    min-height: 40px;
    width: 88px;
    height: 18px;
    font-size: 14px;
    border-color: #fff;
    font-family: 'DM Sans';
}

#pricing-btn-deactive {
    background-color: transparent;
    color: #fff;
    min-height: 40px;
    width: 88px;
    height: 18px;
    font-size: 14px;
    border-color: transparent;
    font-family: 'DM Sans';
}

.pricing-landlord-card-black {
    width: auto;
    height: 100%;
    background-color: #020718 ;
    border: 1px solid rgba(243,244,245,0.15);
    color: #fff;
    border-radius: 10px;
}

#pricing-landlord-card-black-img {
    width: 50px;
    margin-left: 20px;
    margin-top: 20px;
}
.pricing-landlord-card-white {
    width:auto;
    height: 100%;
    background-color: #fff ;
    border: 1px solid rgba(243,244,245,0.15);
    color: #020718;
    border-radius: 10px;
}

#pricing-landlord-card-white-img {
    width: 50px;
    margin-left: 20px;
    margin-top: 20px;
}


#pricing-btn-active:focus,#pricing-btn-active:active {
    outline: none !important;
    box-shadow: none !important;
    border:none !important;
 }


 .buyPlanBtn {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: inline-block;
    text-decoration: none;
    font-size: inherit;
    color: white;
    background-color: #d35400;
    border: 0;
    font-family: DM Sans;
    border-radius: 0px;
    cursor: pointer;
    margin-left: 10px;
}

.buyPlanBtn:hover {
  background-color: #f39c12;
}
.buyPlanBtn:active {
  background-color: #f39c12;
}
.buyPlanBtn:focus {
  background-color: #f39c12;
  outline: none !important;
  box-shadow: none !important;
}
