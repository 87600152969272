.dashboardBtn{
    background-color: rgb(8, 8, 8) !important;
    text-decoration: none;
    color: #fff;
    font-family: DM Sans;
    font-size: 13px;
    border-radius: 10px;
}

.dashboardBtn:hover{
    background-color: rgb(117, 111, 111) !important;
    text-decoration: none;
    color: #fff;
    font-family: DM Sans;
    font-size: 13px;
    border-radius: 10px;
}