.footer-container {
    background-color: #020718;
    margin-top: 30px;
}

#footer-btn {
    background-color: #fff;
    transition: 1s;
    color: #343D48;
    min-height: 40px;
    width: 180px;
    height: 18px;
    font-size: 18px;
    border-color: #fff;
    font-family: 'DM Sans';
    border-radius: 0;
}
#footer-btn:hover{
    background-color: #bdc3c7;
    transition: 1s;
    color: #343D48;
    min-height: 40px;
    width: 180px;
    height: 18px;
    font-size: 18px;
    border-color: #bdc3c7;
    font-family: 'DM Sans';
    border-radius: 0;
}